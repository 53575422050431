.form_fields{
    margin-bottom: 1rem;;
}
.desc_input{
    width: 100%;
    line-height: 2rem;
    /* padding: .3rem; */
    font-size: 1.2rem;
    margin-top: .3rem;
    margin-bottom: .3rem;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.form{
    position: relative;
}

.select_input {
    padding: .3rem;
}

button {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    display: inline-block;
    height: 50px;
    min-width: 88px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: rgba(16, 166, 227, 1);
    color: #fff;
    outline: 0;
    width: 100%;
    margin-top: 1rem;
}

button:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
}
.loader-title {
    font-size: 2rem;
    font-weight: 600;
}