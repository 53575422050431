body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Rubik', sans-serif;
}

h3{
  font-weight: 300;
  font-size: 1.8rem;
  letter-spacing: 4px;
  margin-bottom: .5rem;
}
h1{
  font-weight: 300;
  font-size: 3rem;
  /* letter-spacing: 6px; */
  margin-bottom: 0;
  margin-top: .5rem;
  color: rgba(16, 166, 227, 1);
  text-shadow: 0px 0px 10px rgba(255,255,255,0.7);
}

.dark-text{
    color: #1d1d1b;
}
.blue-text{
    color: #00a4e0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}