.file-package{
    background-color: #eee;
    padding: 1rem;
    margin-bottom: .5rem;
}

.file-package ul{
    margin: 0;
    padding-left: 1rem;
}

.file-package h5{
    margin-top: 0rem;
    margin-bottom: .5rem;
}

.file-packages{
    position: relative;
    min-height: 10rem;
}