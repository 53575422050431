.dropzone {
  height: 200px;
  width: 100%;
  background-color: #eeeeee;
  font-size: 16px;
  transition: background-color ease-in 0.3s;
  border: 1px dashed #494949;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.upload-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.highlight {
  background-color: rgb(16, 166, 227);
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.file-input {
  display: none;
}
