.app {
  background-color: rgb(255, 255, 255);
  min-height: 100vh !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.rng-background{
    background-image: url(/header.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -2rem;
}