body {
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Rubik', sans-serif;
}

h3{
  font-weight: 300;
  font-size: 1.8rem;
  letter-spacing: 4px;
  margin-bottom: .5rem;
}
h1{
  font-weight: 300;
  font-size: 3rem;
  /* letter-spacing: 6px; */
  margin-bottom: 0;
  margin-top: .5rem;
  color: rgba(16, 166, 227, 1);
  text-shadow: 0px 0px 10px rgba(255,255,255,0.7);
}

.dark-text{
    color: #1d1d1b;
}
.blue-text{
    color: #00a4e0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.app {
  background-color: rgb(255, 255, 255);
  min-height: 100vh !important;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.rng-background{
    background-image: url(/header.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -2rem;
}
.dropzone {
  height: 200px;
  width: 100%;
  background-color: #eeeeee;
  font-size: 16px;
  transition: background-color ease-in 0.3s;
  border: 1px dashed #494949;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.upload-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.highlight {
  background-color: rgb(16, 166, 227);
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.file-input {
  display: none;
}

.content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
}

.actions {
    display: flex;
    flex: 1 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 1rem;
}

.title {
    margin-bottom: 32px;
    color: #555;
}

.check-icon {
    opacity: 0.5;
    margin-left: 32px;
}

.progress-wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
}

.file-upload-list{
    overflow-y: hidden;
}
.files {
    height: 100%;
    overflow-y: scroll;
    width: 50%;
}

.files-empty-placeholder{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filename {
    font-size: 16px;
    color: #555;
}

.row {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    cursor: default;
}
.danger-text{
    color: #9e1f1f;
}

.delete-file {
    cursor: pointer;
    color: #9e1f1f;
}

.delete-file {
    color: #750b0b;
    transition: color .5s;
    text-decoration: underline;
}
.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(16, 166, 227, 0.5);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(10, 160, 220, 1);;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.form_fields{
    margin-bottom: 1rem;;
}
.desc_input{
    width: 100%;
    line-height: 2rem;
    /* padding: .3rem; */
    font-size: 1.2rem;
    margin-top: .3rem;
    margin-bottom: .3rem;
    box-sizing: border-box;
}
.form{
    position: relative;
}

.select_input {
    padding: .3rem;
}

button {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    display: inline-block;
    height: 50px;
    min-width: 88px;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: rgba(16, 166, 227, 1);
    color: #fff;
    outline: 0;
    width: 100%;
    margin-top: 1rem;
}

button:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
}
.loader-title {
    font-size: 2rem;
    font-weight: 600;
}
.file-package{
    background-color: #eee;
    padding: 1rem;
    margin-bottom: .5rem;
}

.file-package ul{
    margin: 0;
    padding-left: 1rem;
}

.file-package h5{
    margin-top: 0rem;
    margin-bottom: .5rem;
}

.file-packages{
    position: relative;
    min-height: 10rem;
}
