.content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
}

.actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 1rem;
}

.title {
    margin-bottom: 32px;
    color: #555;
}

.check-icon {
    opacity: 0.5;
    margin-left: 32px;
}

.progress-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.file-upload-list{
    overflow-y: hidden;
}
.files {
    height: 100%;
    overflow-y: scroll;
    width: 50%;
}

.files-empty-placeholder{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filename {
    font-size: 16px;
    color: #555;
}

.row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    cursor: default;
}
.danger-text{
    color: #9e1f1f;
}

.delete-file {
    cursor: pointer;
    color: #9e1f1f;
}

.delete-file {
    color: #750b0b;
    transition: color .5s;
    text-decoration: underline;
}