.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(16, 166, 227, 0.5);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(10, 160, 220, 1);;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
